<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Historial de archivos multimedia</h1>
                <p class="desc mt-0 mb-1">
                    Aquí puedes ver el historial de los archivos multimedia que han sido subidos por los usuarios.
                </p>
            </v-alert>
        </v-col>

        <!-- Search Bar -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" color="#8061c2" label="Buscar " append-icon="mdi-magnify" single-line
                    hide-details outlined class="mb-2 mx-0 px-0" @input="fetchMedia"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0 ">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>


        <v-data-table :headers="headers" :items="media" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
            :items-per-page="perPage" :server-items-length="totalMedia" :page.sync="page" :footer-props="{
                itemsPerPageOptions: [50, 100, 200, 300, -1],
                showFirstLastPage: true
            }" @update:page="fetchMedia" @update:items-per-page="changePerPage">

            <!--<template v-slot:item.role.name="{ item }">
                <v-chip :color="getRoleColor(item.role.name)" dark small>
                    {{ item.role.name }}
                </v-chip>
            </template>

            <template v-slot:item.age="{ item }">
                {{ item.age_id != null ? item.age.name : 'N/A' }}
            </template>

            <template v-slot:item.gender="{ item }">
                {{ item.gender_id != null ? item.gender.name : 'N/A' }}
            </template>-->

            <template v-slot:item.creator_type="{ item }">
                <v-chip :color="getCreatorColor(item.creator_type_id)" dark small>
                    {{ item.creator_type_id != null ? item.creator_type.name : 'N/A' }}
                </v-chip>
            </template>

            <template v-slot:item.path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="miniatura clickable-media" @click="showMedia(item.path, item.type)"
                            @mouseover="hoveredMedia = item.path" @mouseleave="hoveredMedia = null" v-bind="attrs"
                            v-on="on">
                            <template v-if="item.type === 'video'">
                                <video :src="item.path" :autoplay="true" :loop="true" muted :preload="'auto'"
                                    :playsinline="true" v-if="item.path"></video>
                            </template>
                            <template v-else>
                                <img :src="item.path" v-if="item.path" />
                            </template>
                        </div>
                    </template>
                    <span>Ver</span>
                </v-tooltip>
            </template>

            <!--<template v-slot:item.status_media="{ item }">
                <v-chip :color="getStatusColor(item.status_media_id)" dark small>
                    {{ item.status_media }}
                </v-chip>
            </template>-->


            <template v-slot:item.apro="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="approveMedia(item.id)"
                            :loading="item.loading" :disabled="item.loading">
                            <v-icon>mdi-image-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprobar</span>
                </v-tooltip>


                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="red" v-bind="attrs" v-on="on" :loading="item.loading"
                            :disabled="item.loading" @click="rejectMedia(item.id)">
                            <v-icon>mdi-image-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>Rechazar</span>
                </v-tooltip>
            </template>


            <template v-slot:item.apro_all="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="approveMediaAll(item)"
                            :loading="loading" :disabled="loading">
                            <v-icon>mdi-checkbox-multiple-marked</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprobar todo del harmony</span>
                </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site_slug)">
                            <v-icon>mdi-web</v-icon>
                        </v-btn>
                    </template>
                    <span>Ir al Harmony</span>
                </v-tooltip>

                <!-- Botón para impersonar usuario con tooltip -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on"
                            @click="impersonateUser(item.user_id)">
                            <v-icon>mdi-account-switch</v-icon>
                        </v-btn>
                    </template>
                    <span>Impersonar Usuario</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openEditDialog(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar Harmony</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog transition="dialog-top-transition" max-width="600" v-model="editDialog">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formEditUser" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0" class="col-12 px-10 py-10 pb-4 mb-0">

                            <div class="text-left">
                                <v-row class="mb-0">
                                    <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">Actualizar información</h2>
                                </v-row>
                                <p class="mb-8 desc">Los datos del harmony <strong>{{ editedSite.site }}</strong>, serán actualizados.</p>
                            </div>
                            <div class="text-left mb-8">
                                <v-row>
                                    <div class="col-12 col-md-6 p-0 m-0">
                                        <v-select v-model="editedSite.gender_id" hide-details :items="genders" item-text="name" item-value="id" outlined label="Género" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                    <div class="col-12 col-md-6 p-0 m-0">
                                        <v-select v-model="editedSite.age_id" hide-details :items="ages" item-text="name" item-value="id" outlined label="Edad" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                </v-row>

                                <v-row>
                                    <div class="col-12 col-md-12 p-0 m-0">
                                        <v-select v-model="editedSite.creator_type_id" hide-details :items="creator_types" item-text="name" item-value="id" outlined label="Tipo" color="#8e84c0" class="col-12 col-md-6">
                                        </v-select>
                                    </div>
                                </v-row>
                            </div>
                            <div class="text-right">
                                <v-btn :disabled="saveLoading" @click="updateUser" :loading="saveLoading" color="#8e84c0" class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                    <v-icon left color="white">mdi-account-check-outline</v-icon>
                                    Actualizar
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="95vh" width="unset" class="rounded-lg dialog-media">
            <div class="media-container">
                <video v-if="mediaType === 'video'" :src="selectedLogo" :autoplay="true" :loop="true" muted
                    :preload="'auto'" :playsinline="true" class="transition-ease cursor-pointer media"></video>
                <img v-else :src="selectedLogo" class="transition-ease cursor-pointer media" :alt="'Image'" />
            </div>
        </v-dialog>
    </v-col>
</template>

<script>
import axios from '@/axios.js';
import impersonationMixin from '@/mixins/impersonationMixin';

export default {
    name: 'PendingMedia',
    mixins: [impersonationMixin],
    data() {
        return {
            search: '',
            loading: true,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            media: [],
            totalMedia: 0,
            dialog: false,
            dialogDetails: false,
            saveLoading: false,
            roles: [],
            genders: [],
            ages: [],
            creator_types: [],
            editedSite: {},
            editDialog: false,
            mediaType: 'image',
            filter: 'created_at',
             order: 'asc',
            headers: [
                { text: 'Usuario', value: 'email' },
                /*{
                    text: 'Edad',
                    value: 'age',
                    align: 'start'
                },
                {
                    text: 'Género',
                    value: 'gender',
                    align: 'start'
                },
                {
                    text: 'Rol',
                    value: 'role.name',
                    align: 'center'
                },*/
                {
                    text: 'Tipo',
                    value: 'creator_type',
                    align: 'center'
                },
                { text: 'Harmony', value: 'site' },
                { text: 'Imagen/Video', value: 'path', align: 'start', sortable: false },
                /*{ text: 'Estado', value: 'status_media' },*/
                { text: 'Fecha', value: 'created_at' },
                {text: 'Aprobar/Rechazar', value: 'apro',  align: 'center',  sortable: false},
                {text: 'Aprobar todo', value: 'apro_all',  align: 'center',  sortable: false},
                { text: 'Opciones', value: 'actions', sortable: false }
            ],
            filterOptions: [
                {
                    text: 'Fecha Creación',
                    value: 'created_at'
                },
            ],
            orderOptions: [
                {
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
        };
    },


    created() {
        this.fetchMedia();
        this.getRoles();
        this.getGenders();
        this.getAges();
        this.getCreatorTypes();
    },

    methods: {

        getCreatorColor(creator_id) {
            if (creator_id === 1) {
                return '#ed6f33';
            } else if (creator_id === 2) {
                return '#62a1e1';
            }else if (creator_id === 3) {
                return '#e162ba';
            }else if (creator_id === 4) {
                return '#00936a';
            }
            return 'grey';
        },

        showMedia(media, type) {
            this.mediaType = type;
            this.selectedLogo = media;
            this.dialog = true;
        },

        showDetails(item) {
            this.withdrawalMethod = item.payment_method;
            this.paymentDetails = item.payment_details || {};
            this.dialogDetails = true;
        },

        async fetchMedia(){
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-media', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        list: 'pending_media',
                        order: this.order,
                        filter: this.filter
                    }
                });
                this.media = response.data.media.map(media => ({
                    ...media,
                    loading: false
                }));
                this.totalMedia = response.data.total;

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        openEditDialog(site) {
            this.editedSite = {
                ...site
            };
            this.editDialog = true;
        },

        approveMedia(id) {
            const index = this.media.findIndex(media => media.id === id);
            this.$swal({
                title: 'Aprobar archivo',
                text: '¿Estás seguro de que deseas aprobar este archivo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.media[index].loading = true;
                    try {
                        const response = await axios.post('/admin/approve-media', {
                            id: id
                        });
                        this.fetchMedia();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Archivo aprobado',
                                text: 'El archivo ha sido aprobado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al aprobar el archivo');
                    } finally {
                        this.$root.$emit('updateMedia');
                        this.media[index].loading = false;
                    }
                }
            });
        },
        approveMediaAll(item) {
            const indices = this.media.reduce((acc, media, index) => {
                if (media.site_slug === item.site_slug) {
                    acc.push(index);
                }
                return acc;
            }, []);
            this.$swal({
                title: 'Aprobar archivos',
                text: '¿Estás seguro de que deseas aprobar todos los archivos de este harmony?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    indices.forEach(index => {
                        this.media[index].loading = true;
                    });
                    try {
                        const response = await axios.post('/admin/approve-media-all', {
                            site_setting_id: item.site_setting_id
                        });
                        this.fetchMedia();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Archivos aprobados',
                                text: 'Los archivos han sido aprobados exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al aprobar el archivo');
                    } finally {
                        this.$root.$emit('updateMedia');
                        indices.forEach(index => {
                            this.media[index].loading = false;
                        });
                    }
                }
            });
        },

        async rejectMedia(id) {
            const index = this.media.findIndex(media => media.id === id);
            this.$swal({
                title: 'Rechazar archivo',
                text: '¿Estás seguro de rechazar el archivo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.media[index].loading = true;
                    try {
                        const response = await axios.post('/admin/reject-media', {
                            id: id
                        });
                        this.fetchMedia();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Archivo rechazado',
                                text: 'El archivo ha sido rechazado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al rechazar el archivo');
                    } finally {
                        this.$root.$emit('updateMedia');
                        this.media[index].loading = false;
                    }
                }
            });
        },

        async updateUser() {
            this.saveLoading = true;
            try {
                await axios.post('/admin/update-creator', this.editedSite);

                this.$vs.notify({
                    title: 'Actualización',
                    text: 'Se han guardado los datos correctamente',
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                this.editDialog = false;
                this.fetchMedia();
            } catch (error) {
                this.$vs.notify({
                    title: 'Error',
                    text: 'No se ha podido actualizar los datos',
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                    time: 5000,
                });
                console.error(error);
            } finally {
                this.saveLoading = false;
            }
        },

        async getRoles() {
            try {
                const response = await axios.get('/admin/get-roles'); // Nueva llamada para obtener los roles
                this.roles = response.data.roles;
            } catch (error) {
                console.error(error);
            }
        },
        async getGenders() {
            try {
                const response = await axios.get('/admin/get-genders');
                this.genders = response.data.genders;
            } catch (error) {
                console.error(error);
            }
        },
        async getAges() {
            try {
                const response = await axios.get('/admin/get-ages');
                this.ages = response.data.ages;
            } catch (error) {
                console.error(error);
            }
        },
        async getCreatorTypes() {
            try {
                const response = await axios.get('/admin/get-creator-types');
                this.creator_types = response.data.creatorTypes;
            } catch (error) {
                console.error(error);
            }
        },

        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },

        getRoleColor(roleName) {
            if (roleName.toLowerCase() === 'admin') {
                return 'rgb(247 115 115)';
            } else if (roleName.toLowerCase() === 'user') {
                return '#a9a9a9';
            }
            return 'blue'; // Color por defecto
        },
        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchMedia();
        },

        getStatusColor(statusName) {
            if (statusName === 1 ) {
                return '#2e8b57';
            } else if (statusName === 2) {
                return '#f29339';
            } else if (statusName === 3) {
                return '#f44336';
            }
        },
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchMedia();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchMedia();
            }
        }
    },
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

 .miniatura {
  width: 100px;
  height: 40px;
  overflow: hidden;
}

.clickable-media {
  cursor: pointer;
}

.miniatura img,
.miniatura video {
  width: 100%;
  height: 100%;
} 

.dialog-media {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-media .v-dialog {
    box-shadow: none !important;
}

.media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
}

.media {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.v-dialog__content--active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-dialog__content--active .v-dialog {
    width: auto !important;
    max-width: 95vh;
}
</style>
